import i18nextConfig from '../../../next-i18next.config'

export const getLocalePathFromPathname = (
  pathname: string,
  locale: string,
): string => {
  if (locale === process.env.NEXT_PUBLIC_DEFAULT_LOCALE) {
    return pathname === '/[locale]' || pathname === '/'
      ? '/'
      : pathname.replace('/[locale]', '') + '/'
  }
  return pathname.includes('[locale]')
    ? pathname.replace('[locale]', locale) + '/'
    : `/${locale}${pathname}`
}

export const extractLocaleFromURL = (path: string) => {
  let locale = path.split('/')[1]
  if (!i18nextConfig.i18n.locales.includes(locale)) {
    locale = i18nextConfig.i18n.defaultLocale
  }

  return locale
}

/**
 * Return app base url with locale
 * @param locale
 */
export const getBaseUrl = (locale: string): string => {
  if (locale === process.env.NEXT_PUBLIC_DEFAULT_LOCALE) {
    return window.location.origin
  }
  return window.location.origin + '/' + locale
}
