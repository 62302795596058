import '../styles/globals.css'
import '../styles/animations.css'
import React, { useEffect, useMemo } from 'react'
import { NextComponentType } from 'next'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { AppContext, AppInitialProps, AppLayoutProps } from 'next/app'
import { appWithTranslation } from 'next-i18next'
import AuthProvider from '../providers/AuthProvider'
import AxiosInterceptor from '../providers/AxiosInterceptor'
import MultiversxProvider from '../providers/MultiversxProvider'
import Script from 'next/script'
import { DefaultSeo } from 'next-seo'
import { getLocalePathFromPathname } from '../lib/utils/language'
import i18nextConfig from '../../next-i18next.config'
import { useRouter } from 'next/router'
import dayjs from 'dayjs'
import { NotificationProvider } from '../providers/NotificationProvider'
import propertyService from '../services/property.service'
import { setProperties, useDispatch, ReduxProvider } from '../store'
import MultiversxInterceptor from '../providers/MultiversxInterceptor'
import useLocale from '../hooks/useLocale'
const LocalizedFormat = require('dayjs/plugin/localizedFormat')
const calendar = require('dayjs/plugin/calendar')
require('dayjs/locale/fr')
dayjs.extend(LocalizedFormat)
dayjs.extend(calendar)

const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID

if (process.env.NEXT_PUBLIC_BUGSNAG_API_KEY) {
  Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ['staging', 'production'],
    releaseStage: process.env.NEXT_PUBLIC_TARGET_ENV,
  })
}

function BaseLayout() {
  const dispatch = useDispatch()
  const { locale } = useLocale()

  const loadProperties = async () => {
    const { properties } = await propertyService.getProperties({
      order: 'DESC',
      lang: locale,
    })
    dispatch(setProperties(properties))
  }

  useEffect(() => {
    loadProperties()
    dayjs.locale(locale)
  }, [locale])

  return null
}

const App: NextComponentType<AppContext, AppInitialProps, AppLayoutProps> = ({
  Component,
  pageProps,
}: AppLayoutProps) => {
  const router = useRouter()
  const currentLocale = (router.query.locale ||
    i18nextConfig.i18n.defaultLocale) as string
  const otherLocales = i18nextConfig.i18n.locales.filter(
    (locale: string) => locale !== currentLocale,
  )
  const getLayout = Component.getLayout ?? ((page) => page)

  const path = useMemo(
    () => getLocalePathFromPathname(router.route, currentLocale),
    [router.route, currentLocale],
  )

  return (
    <ReduxProvider>
      <AuthProvider>
        <AxiosInterceptor>
          <NotificationProvider>
            <MultiversxProvider>
              <MultiversxInterceptor>
                {GTM_ID && typeof window !== 'undefined' && (
                  <Script
                    id="google-tag-manager"
                    strategy="afterInteractive"
                    dangerouslySetInnerHTML={{
                      __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');`,
                    }}
                  ></Script>
                )}
                {GTM_ID && (
                  <noscript
                    dangerouslySetInnerHTML={{
                      __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
                    }}
                  ></noscript>
                )}
                <DefaultSeo
                  canonical={process.env.NEXT_PUBLIC_BASEURL + path}
                  openGraph={{
                    type: 'website',
                    locale: currentLocale,
                    url: `${process.env.NEXT_PUBLIC_BASEURL}${path}`,
                    siteName: 'Shelters',
                    images: [
                      {
                        url: `${process.env.NEXT_PUBLIC_WWW_BASEURL}/img/logos/shelters/logo-full-x1700.png`,
                        width: 1700,
                        height: 899,
                        alt: 'Shelters logo',
                      },
                    ],
                  }}
                  languageAlternates={otherLocales
                    .map((locale: string) => ({
                      hrefLang: locale,
                      href: `${
                        process.env.NEXT_PUBLIC_BASEURL
                      }${getLocalePathFromPathname(router.route, locale)}`,
                    }))
                    .concat({
                      hrefLang: 'x-default',
                      href: `${
                        process.env.NEXT_PUBLIC_BASEURL
                      }${getLocalePathFromPathname(
                        router.route,
                        process.env.NEXT_PUBLIC_DEFAULT_LOCALE,
                      )}`,
                    })}
                  twitter={{
                    site: '@' + process.env.NEXT_PUBLIC_TWITTER_ACCOUNT,
                    cardType: 'summary_large_image',
                  }}
                  facebook={{
                    appId: process.env.NEXT_PUBLIC_FACEBOOK_PAGE_ID,
                  }}
                  themeColor="rgba(51,40,154,1)"
                  additionalLinkTags={[
                    {
                      rel: 'icon',
                      href: '/favicon.ico',
                    },
                  ]}
                />
                <BaseLayout />
                {getLayout(<Component {...pageProps} />)}
              </MultiversxInterceptor>
            </MultiversxProvider>
          </NotificationProvider>
        </AxiosInterceptor>
      </AuthProvider>
    </ReduxProvider>
  )
}

export default appWithTranslation(App)
