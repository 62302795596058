import React from 'react'
import { EnumDictionary } from '../lib/utils/enumDictionary'
import Link from 'next/link'
import { classNames } from '../lib/utils/classNames'

export enum buttonHierarchy {
  primary,
  secondary,
  tertiary,
  custom,
}
const buttonStyleDictionary: EnumDictionary<buttonHierarchy, string> = {
  [buttonHierarchy.primary]:
    'block text-neutralWhite font-medium border bg-brandLightBlue border-brandLightBlue/90 hover:bg-brandDarkBlue/80 hover:border-brandDarkBlue/10 disabled:border-neutralGray',
  [buttonHierarchy.secondary]:
    'block bg-neutralWhite text-brandDarkPurple border border-brandDarkPurple',
  [buttonHierarchy.tertiary]:
    'tertiary border border-gray-400/80 text-black bg-white/5 backdrop-blur-md',
  [buttonHierarchy.custom]: '',
}

export enum buttonShape {
  rectangle,
  pill,
  custom,
}
const buttonShapeDictionary: EnumDictionary<buttonShape, string> = {
  [buttonShape.rectangle]: 'rounded-lg',
  [buttonShape.pill]: 'rounded-[20em]',
  [buttonShape.custom]: '',
}

interface ButtonProps {
  link?: string
  onClick?: () => void
  type?: 'button' | 'submit' | 'reset' | undefined
  hierarchy: buttonHierarchy
  shape: buttonShape
  className?: string
  children?: any
  disabled?: boolean
}
const LinkButton = ({
  link,
  onClick,
  hierarchy,
  children,
  shape,
  className,
  type,
  disabled = false,
}: ButtonProps) => {
  return (
    <>
      {link ? (
        <Link
          href={link}
          className={classNames(
            buttonStyleDictionary[hierarchy],
            buttonShapeDictionary[shape],
            className ? className : '',
            'flex items-center justify-center',
          )}
        >
          {children}
        </Link>
      ) : (
        <button
          onClick={onClick}
          type={type || undefined}
          className={classNames(
            buttonStyleDictionary[hierarchy],
            buttonShapeDictionary[shape],
            disabled ? 'disabled-button' : '',
            className ? className : '',
            'flex items-center justify-center',
          )}
          disabled={disabled}
        >
          {children}
        </button>
      )}
    </>
  )
}

export default LinkButton
