import {
  PencilIcon,
  UserIcon,
  WalletIcon,
  LockClosedIcon,
  BellIcon,
  DocumentIcon,
  WrenchIcon,
  ChevronUpDownIcon,
  CheckIcon,
  ArrowPathIcon,
  XCircleIcon,
  XMarkIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  EyeSlashIcon,
  EyeIcon,
  MagnifyingGlassIcon,
  HomeIcon,
  ChartBarIcon,
  BanknotesIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowLeftIcon,
  ShareIcon,
  MapPinIcon,
  PhotoIcon,
  MapIcon,
  UsersIcon,
  ChartPieIcon,
  RectangleGroupIcon,
  WrenchScrewdriverIcon,
  ArrowsPointingOutIcon,
  ArrowsUpDownIcon,
  CubeTransparentIcon,
  LightBulbIcon,
  SparklesIcon,
  MoonIcon,
  QueueListIcon,
  TruckIcon,
  LifebuoyIcon,
  BarsArrowUpIcon,
  Bars3Icon,
  UserMinusIcon,
  UserPlusIcon,
  Squares2X2Icon,
  BuildingStorefrontIcon,
  ReceiptPercentIcon,
  Cog6ToothIcon,
  ArrowRightOnRectangleIcon,
  GlobeAltIcon,
  CurrencyEuroIcon,
  CurrencyDollarIcon,
  EllipsisHorizontalCircleIcon,
  ChevronUpIcon,
  ArrowPathRoundedSquareIcon,
  ArrowRightIcon,
  PencilSquareIcon,
  PlusCircleIcon,
  TrashIcon,
  ArchiveBoxIcon,
  BookOpenIcon,
  BookmarkSquareIcon,
  ChatBubbleBottomCenterIcon,
  CreditCardIcon,
  InformationCircleIcon,
  ShieldCheckIcon,
  KeyIcon,
  ExclamationTriangleIcon,
  ArrowDownOnSquareIcon,
  PlusIcon,
  BuildingOffice2Icon,
  DevicePhoneMobileIcon,
  ComputerDesktopIcon,
  DeviceTabletIcon,
  ExclamationCircleIcon,
  ArrowRightCircleIcon,
  NewspaperIcon,
  LinkIcon,
  FaceFrownIcon,
} from '@heroicons/react/20/solid'

export const HeroIcons = {
  PencilIcon,
  UserIcon,
  WalletIcon,
  LockClosedIcon,
  BellIcon,
  DocumentIcon,
  WrenchIcon,
  ChevronUpDownIcon,
  CheckIcon,
  ArrowPathIcon,
  XCircleIcon,
  XMarkIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  EyeSlashIcon,
  EyeIcon,
  MagnifyingGlassIcon,
  HomeIcon,
  ChartBarIcon,
  BanknotesIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowLeftIcon,
  ShareIcon,
  MapPinIcon,
  PhotoIcon,
  MapIcon,
  UsersIcon,
  ChartPieIcon,
  RectangleGroupIcon,
  WrenchScrewdriverIcon,
  ArrowsPointingOutIcon,
  ArrowsUpDownIcon,
  CubeTransparentIcon,
  LightBulbIcon,
  SparklesIcon,
  MoonIcon,
  QueueListIcon,
  TruckIcon,
  LifebuoyIcon,
  BarsArrowUpIcon,
  Bars3Icon,
  UserMinusIcon,
  UserPlusIcon,
  Squares2X2Icon,
  BuildingStorefrontIcon,
  ReceiptPercentIcon,
  Cog6ToothIcon,
  ArrowRightOnRectangleIcon,
  GlobeAltIcon,
  CurrencyEuroIcon,
  CurrencyDollarIcon,
  EllipsisHorizontalCircleIcon,
  ChevronUpIcon,
  ArrowPathRoundedSquareIcon,
  ArrowRightIcon,
  PencilSquareIcon,
  PlusCircleIcon,
  TrashIcon,
  ArchiveBoxIcon,
  BookOpenIcon,
  BookmarkSquareIcon,
  ChatBubbleBottomCenterIcon,
  CreditCardIcon,
  InformationCircleIcon,
  ShieldCheckIcon,
  KeyIcon,
  ExclamationTriangleIcon,
  ArrowDownOnSquareIcon,
  PlusIcon,
  BuildingOffice2Icon,
  DevicePhoneMobileIcon,
  ComputerDesktopIcon,
  DeviceTabletIcon,
  ExclamationCircleIcon,
  ArrowRightCircleIcon,
  NewspaperIcon,
  LinkIcon,
  FaceFrownIcon,
}
