import React from 'react'
import { classNames } from '../lib/utils/classNames'

type CardProps = {
  children: React.ReactNode
  className?: string
}

export default function Card({ children, className }: CardProps) {
  return (
    <div
      className={classNames(
        'relative rounded-2xl shadow-sm backdrop-blur-lg',
        className || '',
      )}
    >
      {children}
    </div>
  )
}
